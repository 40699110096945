import React from "react"
import { LocalizedLink } from "gatsby-theme-i18n"

import ImgL1Logo from "../../assets/image/svg/AMAI-AI-Experts.inline.svg"
import ImgL1LogoWhite from "../../assets/image/svg/AMAI-AI-Experts-white.inline.svg"

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <LocalizedLink
      to="/"
      className={`${className}`}
      aria-label="AMAI Logo"
      {...rest}
    >
      {white ? (
        <ImgL1LogoWhite style={{ width: 100, height: 38 }} />
      ) : (
        <ImgL1Logo style={{ width: 100, height: 38 }} />
      )}
    </LocalizedLink>
  )
}

export default Logo
